<template>
    <v-container fluid class="container pa-5 mt-3">
        <v-row v-if="!isInRole(43)">
            <v-col cols="12" lg="6" md="12" sm="12">
                <v-row>
                    <v-col cols="12" lg="6" md="3" sm="12">
                        <Material-Stats-Card v-bind="{ ...stats[0] }" />
                    </v-col>
                    <v-col cols="12" lg="6" md="3" sm="12">
                        <Material-Stats-Card v-bind="{ ...stats[1] }" />
                    </v-col>
                    <v-col cols="12" lg="6" md="3" sm="12">
                        <Material-Stats-Card v-bind="{ ...stats[4] }" />
                    </v-col>
                    <v-col cols="12" lg="6" md="3" sm="12">
                        <Material-Stats-Card v-bind="{ ...stats[5] }" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="12">
                <material-card
                    color="primary darken-2"
                    full-header
                    class="mt-5"
                >
                    <template #heading>
                        <div class="white--text">
                            <div class="font-weight-light">
                                {{ $t("TopTenSoldItemsForLastSevenDays") }}
                            </div>
                            <div class="text-caption"></div>
                        </div>
                    </template>
                    <v-card-text>
                        <line-chart
                            v-if="loaded"
                            :chartdata="chartdata"
                            :options="options"
                        />
                    </v-card-text>
                </material-card>
            </v-col>
            <!--  <v-col cols="12" md="6">
                <material-card
                    class="mt-5 text-center"
                    color="green darken-1"
                    full-header
                >
                    <template #heading>
                        <div class="white--text">
                            <div class="font-weight-light">
                                {{ $t("reports.last10Bills") }}
                            </div>
                        </div>
                    </template>
                    <template>
                        <v-card class="mx-auto text-center">
                            <v-card-text>
                                <v-sheet>
                                    <v-sparkline
                                        :value="value"
                                        color="green darken-3"
                                        height="100"
                                        line-width="3"
                                        padding="15"
                                        stroke-linecap="round"
                                    >
                                        <template v-slot:label="item">
                                            {{ item.value }}
                                        </template>
                                    </v-sparkline>
                                </v-sheet>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="justify-center">
                                <v-btn
                                    to="/reports/itemsSalesReport"
                                    block
                                    text
                                >
                                    {{
                                        $t("reports.report") +
                                            " " +
                                            $t("reports.itemsSalesReport")
                                    }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </material-card>
            </v-col> -->
            <!-- <v-col cols="12" md="12">
                <material-card
                    class="mt-5 text-center"
                    color="purple darken-4"
                    full-header
                >
                    <template #heading>
                        <div class="white--text">
                            <div class="font-weight-light">
                                {{ $t("TopTenSoldItemsForLastSevenDays") }}
                            </div>
                        </div>
                    </template>
                    <template>
                        <v-card class="mx-auto text-center">
                            <v-card-text>
                                <v-data-table
                                    :items="topTenItems"
                                    :headers="itemsHeaders"
                                    hide-default-footer
                                    :items-per-page="-1"
                                ></v-data-table>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="justify-center">
                                <v-btn
                                    to="/reports/itemsSalesReport"
                                    block
                                    text
                                >
                                    {{
                                        $t("reports.report") +
                                            " " +
                                            $t("reports.itemsSalesReport")
                                    }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </material-card>
            </v-col> -->
        </v-row>
        <v-row v-if="isInRole(43)">
            <v-col cols="12" lg="3" md="6" sm="12">
                <Material-Stats-Card v-bind="{ ...stats[3] }" />
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12">
                <Material-Stats-Card v-bind="{ ...stats[6] }" />
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12">
                <Material-Stats-Card v-bind="{ ...stats[4] }" />
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12">
                <Material-Stats-Card v-bind="{ ...stats[5] }" />
            </v-col>
        </v-row>
        <!--- Deserved Payments -->
        <v-row>
            <v-col cols="12" md="12">
                <material-card
                    class="mt-5 text-center"
                    color="red darken-3"
                    full-header
                >
                    <template #heading>
                        <div class="white--text">
                            <div class="font-weight-light">
                                {{ $t("DeservedPayments") }}
                            </div>
                        </div>
                    </template>
                    <template>
                        <v-card class="mx-auto text-center">
                            <v-card-text>
                                <v-data-table
                                    :headers="headers"
                                    :search="search"
                                    :items="latePayments"
                                    :items-per-page="-1"
                                    hide-default-footer
                                    :loading="loading"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat>
                                            <!--Search bar-->
                                            <v-text-field
                                                v-model="search"
                                                dense
                                                outlined
                                                append-icon="mdi-magnify"
                                                :label="$t('search')"
                                                hide-details
                                            ></v-text-field>
                                        </v-toolbar>
                                    </template>
                                    <template
                                        v-slot:[`item.actions`]="{ item }"
                                    >
                                        <div class="text-end">
                                            <v-row>
                                                <v-col cols="12"
                                                    ><v-tooltip top>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-icon
                                                                class="mr-2"
                                                                color="primary"
                                                                @click="
                                                                    view(item)
                                                                "
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                mdi-eye
                                                            </v-icon>
                                                        </template>
                                                        <span>
                                                            {{ $t("preview") }}
                                                        </span>
                                                    </v-tooltip></v-col
                                                >
                                            </v-row>
                                        </div>
                                    </template>
                                    <template
                                        v-slot:[`item.paymentDate`]="{ item }"
                                    >
                                        {{
                                            item.paymentDate
                                                | moment("yyyy-MM-DD")
                                        }}
                                    </template>
                                    <template
                                        v-slot:[`item.paymentAmount`]="{ item }"
                                    >
                                        {{
                                            item.paymentAmount | currency("", 3)
                                        }}
                                    </template>
                                    <template
                                        v-slot:[`item.remainingAmount`]="{
                                            item
                                        }"
                                    >
                                        {{
                                            (item.totalAmount -
                                                item.paidAmount)
                                                | currency("", 3)
                                        }}
                                    </template>
                                    <template
                                        v-slot:[`item.totalAmount`]="{
                                            item
                                        }"
                                    >
                                        {{ item.totalAmount | currency("", 3) }}
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </template>
                </material-card>
            </v-col>
        </v-row>
        <v-row> </v-row>
    </v-container>
</template>
<script>
import MaterialStatsCard from "../components/base/MaterialStatsCard.vue";
import MaterialCard from "../components/base/MaterialCard.vue";
import i18n from "../i18n";
import axios from "axios";
import LineChart from "../components/Chart.vue";

export default {
    components: {
        MaterialStatsCard,
        MaterialCard,
        LineChart
    },
    computed: {
        statsList() {
            return this.stats.filter(card => this.checkRoles(card.roles));
        }
    },
    data: () => ({
        loaded: false,
        chartdata: {
            labels: [],
            datasets: []
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            beginAtZero: true,
            scales: {
                xAxes: [
                    {
                        ticks: {
                            fontSize: 12
                        }
                    }
                ]
            }
        },
        latePayments: [],
        search: "",
        bills: null,
        classes: null,
        customers: null,
        items: null,
        topTenItems: [],
        students: null,
        value: [10, 30, 20, 40, 50, 70, 60, 80, 100, 90],
        stats: [
            {
                subIcon: "mdi-plus",
                subText:
                    i18n.t("add") + " " + i18n.t("bill.installmentsSaleBill"),
                subTextLink: "/installmentsSaleBill",
                color: "yellow darken-2",
                icon: "mdi-receipt",
                title: i18n.t("bill.installmentsSaleBill"),
                value: "0",
                roles: [31]
            },
            {
                subIcon: "mdi-plus",
                subText: i18n.t("add") + " " + i18n.t("bill.purchaseBill"),
                subTextLink: "/purchaseBill",
                color: "success",
                icon: "mdi-hand-extended-outline",
                title: i18n.t("bill.purchaseBill"),
                value: "0",
                roles: [31]
            },
            {
                subIcon: "mdi-plus",
                subText: i18n.t("add") + " " + i18n.t("item.item"),
                subTextLink: "/items?showDialog=true",
                color: "orange",
                icon: "mdi-file",
                title: i18n.t("item.items"),
                value: "0",
                roles: [0]
            },
            {
                subIcon: "mdi-plus",
                subText: i18n.t("add") + " " + i18n.t("school.student"),
                subTextLink: "/schools/students?showDialog=true",
                color: "purple",
                icon: "mdi-school",
                title: i18n.t("school.students"),
                value: "0",
                roles: [0]
            },
            {
                subIcon: "mdi-plus",
                subText: i18n.t("add") + " " + i18n.t("voucherTypes.2"),
                subTextLink: "/receiptvoucher/2",
                color: "teal",
                icon: "mdi-account-arrow-down-outline",
                title: i18n.t("voucherTypes.2"),
                value: "0",
                roles: [0]
            },
            {
                subIcon: "mdi-plus",
                subText: i18n.t("add") + " " + i18n.t("voucherTypes.3"),
                subTextLink: "/paymentvoucher/3",
                color: "primary",
                icon: "mdi-account-arrow-up-outline",
                title: i18n.t("voucherTypes.3"),
                value: "0",
                roles: [0]
            },
            {
                subIcon: "mdi-plus",
                subText:
                    i18n.t("add") + " " + i18n.t("itemTemplate.itemTemplate"),
                subTextLink: "/PaymentPlans?showDialog=true",
                color: "yellow darken-3",
                icon: "mdi-account-cash-outline",
                title: i18n.t("itemTemplate.itemTemplates"),
                value: "0",
                roles: [0]
            }
        ],
        itemsHeaders: [
            {
                text: i18n.t("item.itemName"),
                value: "itemName"
            },
            {
                text: i18n.t("item.quantity"),
                value: "quantity"
            }
        ],
        headers: [
            {
                text: i18n.t("customer.fullName"),
                value: "fullName"
            },
            {
                text: i18n.t("customer.phone"),
                value: "phone"
            },
            {
                text: i18n.t("payments.paymentNumber"),
                value: "paymentNumber"
            },
            {
                text: i18n.t("payments.paymentDate"),
                value: "paymentDate"
            },
            {
                text: i18n.t("payments.paymentAmount"),
                value: "paymentAmount"
            },
            {
                text: i18n.t("bill.remainingAmount"),
                value: "remainingAmount"
            },
            {
                text: i18n.t("bill.totalAmount"),
                value: "totalAmount"
            },
            { text: "", value: "actions" }
        ],
        tabs: 0,
        loading: false,
        rules: [value => !!value || i18n.t("ThisFieldIsRequired")],
        menu: null,
        activePicker: null
    }),
    created() {
        this.getData();
    },
    methods: {
        getData() {
            axios
                .get("Dashboard/Get")
                .then(response => {
                    var data = response.data.data;
                    this.value = data.last10BillsItemsCount;
                    this.stats[0].value = String(
                        data.installmentsSaleBillsCount
                    );
                    this.stats[1].value = String(data.purchaseBillsCount);
                    this.stats[2].value = String(data.itemsCount);
                    this.stats[3].value = String(data.studentsCount);
                    this.stats[4].value = String(data.receiptVouchersCount);
                    this.stats[5].value = String(data.paymentVouchersCount);
                    this.stats[6].value = String(data.plansCount);
                })
                .catch(e => {
                    i18n.toast.error(i18n.t("AnErrorOccurredDuringTheProcess"));
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("Dashboard/GetLatePaymetns")
                .then(response => {
                    this.latePayments =
                        response.data.data == null ? [] : response.data.data;
                })
                .catch(e => {
                    i18n.toast.error(i18n.t("AnErrorOccurredDuringTheProcess"));
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("Dashboard/TopTenSoldItem")
                .then(response => {
                    this.topTenItems = response.data.data;

                    var colors = [
                        "rgb(255, 99, 132)",
                        "rgb(75, 192, 192)",
                        "rgb(255, 159, 64)",
                        "rgb(201, 203, 207)",
                        "rgb(255, 205, 86)",
                        "rgb(54, 162, 235)",
                        "rgb(153, 102, 255)"
                    ];

                    for (var i = 0; i < this.topTenItems.length; i++) {
                        this.chartdata.labels.push(
                            this.topTenItems[i].itemName
                        );
                    }

                    this.chartdata.datasets.push({
                        label: this.$t("TopTenSoldItemsForLastSevenDays"),
                        backgroundColor: colors[2],
                        data: this.topTenItems.map(x => x.quantity)
                    });

                    this.loaded = true;
                })
                .catch(e => {
                    i18n.toast.error(i18n.t("AnErrorOccurredDuringTheProcess"));
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        checkRoles(roles) {
            if (roles != null && roles.length > 0) {
                for (var i = 0; i < roles.length; i++) {
                    if (!this.isInRole(roles[i])) {
                        return false;
                    }
                }
                return true;
            }
        },
        view(item) {
            if (!this.isInRole(43))
                this.$router.push({
                    path: `/installmentsSaleBill/${item.billGuid}`
                });
            else
                this.$router.push({
                    path: `/schools/students?customerGuid=${item.customerGuid}`
                });
        }
    }
};
</script>
<style>
.v-application .text-caption {
    font-family: inherit !important;
}
</style>
